import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AdaptiveParameter from "./Parameters/AdaptiveParameter";

const getInitialValues = (parameters) => {
  let values = {};
  for (let parameter of parameters) {
    values[parameter.parameterId] = {};
    let obj = values[parameter.parameterId];
    obj.parameterId = parameter.parameterId;
    obj.scriptParametersId = parameter.scriptParametersId;
    obj.selected = false;
    obj.isBoolean = parameter.parameterType === "boolean" ? true : false;
    obj.value = parameter.defaultValue;
    obj.parameterType = parameter.parameterType;
  }
  return values;
};

function MyParametersSetter({
  parameters,
  onParametersValuesUpdate,
  dxfFiles,
  csvFiles
}) {

  const { t } = useTranslation();

  const valuesReducer = (values, action) => {
    console.log(values, action);
    const parameterId = action.payload.parameterId;
    const bValues = { ...values };
    const obj = bValues[parameterId];
    obj.value = action.payload.value;
    obj.selected = action.payload.selected;
    console.log("DISPATCH");
    console.log("Updated values to:", bValues);
    return bValues;
  };

  const [values, dispatchValues] = useReducer(
    valuesReducer,
    getInitialValues(parameters)
  );

  useEffect(() => {
    const bValues = { ...values };
    const arr = Object.keys(values).map((key) => values[key]);
    onParametersValuesUpdate(arr);
  }, [values]);

  return (
    <ParametersContainer>
      {parameters
        .filter((x) => x.isVisible === 1)
        .map((parameter, index) => (
          <div style={{ marginBottom: "20px" }} key={index}>
            <div style={{ whiteSpace: "pre-wrap", marginLeft: "-8px" }}>
              {parameter.parameterType == "boolean" ||
              parameter.parameterType == "float" ? (
                <Typography variant="subtitle1">
                  {t(`ProccessingBackend:Parameters:${parameter.parameterId}:parameterName`)}
                </Typography>
              ) : (
                ""
              )}
              <Typography variant="caption">{t(`ProccessingBackend:Parameters:${parameter.parameterId}:description`)}</Typography>
            </div>
            <div style={{ marginBottom: "8px" }}>
              <AdaptiveParameter
                parameter={parameter}
                onValueUpdate={dispatchValues}
                dxfFiles={dxfFiles}
                csvFiles={csvFiles}
              />
            </div>
          </div>
        ))}
    </ParametersContainer>
  );
}

//Styling
const ParametersContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 220px;
  margin-top: 8px;
`;
//Styling

export default MyParametersSetter;
