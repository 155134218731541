import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}/processings`;

const controller = {
  getCategories: async function () {
    const response = await axios.get(`${prefix}/get-categories`);
    console.log(
      "Executing getCategories()...\nHTTP response: ",
      JSON.stringify(response.data.data, null, 2)
    );
    return response.data.data;
  },

  getScripts: async function (categoryId) {
    const response = await axios.get(`${prefix}/get-scripts/${categoryId}`);
    console.log(
      "Executing getScripts()...\nHTTP response: ",
      JSON.stringify(response.data.data, null, 2)
    );
    return response.data.data;
  },

  getParameters: async function (scriptId) {
    const response = await axios.get(`${prefix}/get-parameters/${scriptId}`);
    console.log(
      "Executing getParameters()...\nHTTP response: ",
      JSON.stringify(response.data.data, null, 2)
    );
    return response.data.data;
  },

  initiateProcessing: async function (data) {
    const response = await axios.post(`${prefix}/create-one`, data);
    return response;
  },

  getDxfFiles: async function (projectId) {
    const response = await axios.get(`${prefix}/get-input-files/${projectId}`);
    console.log(response.data.data.dxfFiles);
    return response.data.data.dxfFiles;
  },
  
  getCsvFiles: async function(projectId) {
    const response = await axios.get(`${prefix}/get-input-files/${projectId}`);
    console.log(response.data.data.csvFiles);
    return response.data.data.csvFiles;
  },
};

export default controller;
