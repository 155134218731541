import React from "react";
import IntegerParameter from "./IntegerParameter";
import FloatParameter from "./IntegerParameter";
import SingleSelectInput from "./SingleSelectInput";
import DxfInput from "./DxfInput";
import CsvInput from "./CsvInput";
import MultipleSelectInput from "./MultipleSelectInput";
import BooleanInput from "./BooleanInput";

function AdaptiveParameter({ parameter, onValueUpdate, dxfFiles, csvFiles }) {
  const renderSwitch = (type) => {
    switch (type) {
      case "integer":
        return <IntegerParameter 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
        />
      case "float":
        return <FloatParameter 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
        />
      case "string":
        return <h4>String</h4>
      case "boolean":
        return <BooleanInput 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
        />
      case "singleSelect":
        return <SingleSelectInput 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
        />
      case "multipleSelect":
        return <MultipleSelectInput 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
        />
      case "lasFile":
        return <h4>las File</h4>
      case "dxfFile":
        return <DxfInput 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
          dxfFiles={dxfFiles}
        />
      case "csvFile":
        return <CsvInput 
          parameter={parameter}
          onValueUpdate={onValueUpdate}
          csvFiles={csvFiles}
        />
    }
  }

  return (
    <React.Fragment>
      {renderSwitch(parameter.parameterType)}
    </React.Fragment>
  );
}

export default AdaptiveParameter;
